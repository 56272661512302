(function () {
  'use strict';
  class Controller {
    constructor() {}
    $count(obj) {
      let total = 0;
      angular.forEach(obj, () => {
        total++;
      });
      return total;
    }
  }
  angular
    .module('supplier.list', [])
    .component('supplierList', {
      controller: Controller,
      templateUrl: 'root/supplier/list/list-supplier.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('supplier.list', {
          abstract: false,
          url: '/list',
          template: '<supplier-list/>'
        });
    });
}());
