(function () {
  'use strict';
  angular
    .module('approval', [
      // Components
      'approval.registerProductSite',
      'approval.updateProduct',
      'approval.updateSite',
      'approval.updateReview',
      'approval.uploadedSites',
      'approval.invalidTier',
      'approval.tierUpdates',
      // Routes
      'approval.list'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/approval', '/approval/list');
      $stateProvider
        .state('approval', {
          abstract: false,
          url: '/approval',
          template: '<ui-view/>'
        });
    });
}());
