<fd-user then="
  $ctrl.$isAdmin = $data.isAdmin;
  $ctrl.$isRep = $data.isRep;
  $ctrl.$userVendors = $data.vendors;"><then><fd-ref ng-if="$ctrl.siteId &amp;&amp; !$ctrl.$current &amp;&amp; $ctrl.$userVendors" fd-ref-path="sites/{{$ctrl.siteId}}/metadata" then="
        $ctrl.$site = $data || {};
        $ctrl.$form.$setPristine();
        $ctrl.$current = true"></fd-ref><fd-ref ng-if="$ctrl.siteId &amp;&amp; !$ctrl.$current &amp;&amp; !$ctrl.$userVendors" fd-ref-path="sites/{{$ctrl.siteId}}/metadataApproval" then="
        $ctrl.$site = $data;
        $ctrl.$form.$setPristine();
        $ctrl.$current = $ctrl.$site"><then><fd-ref ng-if="!$ctrl.$site" fd-ref-path="sites/{{$ctrl.siteId}}/metadata" then="
            $ctrl.$site = $data || {};
            $ctrl.$form.$setPristine();
            $ctrl.$current = true"></fd-ref></then></fd-ref></then></fd-user><div layout="row" layout-align="center"><div class="margin padding-half md-whiteframe-2dp container"><site-update-action ng-if="$ctrl.UPDATE" site="$ctrl.$site" site-id="$ctrl.siteId" then="
        $ctrl.UPDATE = false;
        $ctrl.$current = false" catch="$ctrl.UPDATE = false"></site-update-action><site-remove-action ng-if="$ctrl.REMOVE" site="$ctrl.$site" site-id="$ctrl.siteId" then="
        $ctrl.REMOVE = false;
        $ctrl.$current = false;
        $ctrl.REDIRECT_BACK = true" catch="$ctrl.REMOVE = false"></site-remove-action><wc-ui-redirect ng-if="$ctrl.REDIRECT_BACK" wc-ui-redirect-sref="site.list"></wc-ui-redirect><site-detail ng-if="$ctrl.$site" site="$ctrl.$site" site-id="$ctrl.$siteId" is-disabled="$ctrl.$userVendors" form="$ctrl.$form = $form"></site-detail><div layout="row"><md-button ng-if="$ctrl.$isAdmin" ng-click="$ctrl.REMOVE = true" class="md-warn">DELETE</md-button><div flex="flex"></div><md-button ng-hide="$ctrl.$userVendors" ng-if="$ctrl.$form.$dirty" ng-click="$ctrl.$current = false">CANCEL</md-button><md-button disabled="disabled" ng-show="$ctrl.$userVendors" class="md-raised md-primary"><md-icon>visibility</md-icon>READ ONLY</md-button><md-button ng-hide="$ctrl.$userVendors" ng-disabled="$ctrl.$form.$invalid || $ctrl.$form.$pristine" ng-click="$ctrl.UPDATE = true" class="md-raised md-primary"><span ng-if="$ctrl.$form.$dirty">SAVE</span><span ng-if="$ctrl.$form.$pristine">SAVED</span></md-button></div></div></div>