<fd-user then="
    $ctrl.$user = $data;
    $ctrl.$isAdmin = $data.isAdmin;
    $ctrl.$isRep = $data.isRep;
    $ctrl.registration.agent.displayName = $ctrl.registration.agent.displayName || $ctrl.$user.displayName;
    $ctrl.registration.agent.phone = $ctrl.registration.agent.phone || $ctrl.$user.phone"></fd-user><form name="registrationDetailForm" layout="row" layout-wrap="layout-wrap"><div ng-init="$ctrl.form({$form: registrationDetailForm})"></div><div flex-xs="100" flex-gt-xs="50" layout="column" layout-align="start stretch" class="padding-half"><md-input-container ng-show="$ctrl.$isAdmin" ng-init="
        $ctrl.creationDate = $ctrl.newDate($ctrl.registration.date);
        $ctrl.registration.date = $ctrl.registration.date || $ctrl.creationDate.getTime()" class="no-error-message"><label>Registration date</label><md-datepicker ng-model="$ctrl.creationDate" ng-change="$ctrl.registration.date = $ctrl.creationDate.getTime()"></md-datepicker></md-input-container><md-input-container ng-show="$ctrl.$isAdmin" class="no-error-message"><label>Rep Name</label><input required="required" ng-model="$ctrl.registration.agent.displayName"/></md-input-container><md-input-container ng-show="$ctrl.$isAdmin" class="no-error-message"><label>Rep Telephone</label><input required="required" ng-model="$ctrl.registration.agent.phone"/></md-input-container><md-input-container class="no-error-message"><label>Store Contact Name</label><input required="required" ng-disabled="!$ctrl.$isRep" ng-model="$ctrl.registration.contact.displayName"/></md-input-container><md-input-container class="no-error-message"><label>Store Contact Telephone</label><input ng-disabled="!$ctrl.$isRep" ng-model="$ctrl.registration.contact.phone"/></md-input-container><md-input-container class="no-error-message"><label>Add any notes for this product at this site</label><textarea rows="3" ng-disabled="!$ctrl.$isAdmin &amp;&amp; ($ctrl.isApproval || ($ctrl.registration.isApproved &amp;&amp; !$ctrl.$isRep))" ng-model="$ctrl.registration.notes"></textarea></md-input-container></div><div flex-xs="100" flex-gt-xs="50" layout="column" layout-align="start stretch" class="padding-half"><div layout="row" layout-align="start center" class="md-body-2 padding-v-half"><b>IMAGES</b><div flex="flex"></div><md-button ng-if="$ctrl.$isRep || !($ctrl.isApproval || ($ctrl.registration.isApproved &amp;&amp; !$ctrl.$isRep))" class="md-icon-button relative"><md-icon>add_a_photo</md-icon><input ngf-select="$ctrl.$photoFiles = $files" ngf-multiple="true" ngf-fix-orientation="true" ngf-accept="'image/*'" ngf-resize="{
            width: 800,
            height: 600
          }" class="hidden-input"/><fd-upload ng-repeat="$file in $ctrl.$photoFiles" fd-upload-file="$file" before="$ctrl.percentage = $progress" then="$ctrl.registration.gallery.push($data.url)"><before><wc-dialog wc-dialog-progress="{{$ctrl.percentage}}" wc-dialog-labels="{
                title: 'Uploading (' + $ctrl.percentage + '%)',
                body: 'Please wait until image is fully uploaded.'
              }"></wc-dialog></before><catch><wc-toast wc-toast-message="Error uploading image"></wc-toast></catch></fd-upload></md-button></div><div ng-init="$ctrl.registration.gallery = $ctrl.registration.gallery || []" layout="row" layout-align="center" class="margin-quarter"><div flex="50"><div ng-click="" ng-repeat="image in $ctrl.registration.gallery" class="ratio-4-3 margin-quarter pointer relative"><div ng-style="{ backgroundImage: 'url('+image+')', transform: 'rotate('+($ctrl.registration.galleryRotation[$index] || 0)+'deg)' }" ng-click="$ctrl.IMAGE_EXPANDED = image; $ctrl.EXPANDED_ROTATION = $ctrl.registration.galleryRotation[$index] || 0" class="absolute-fill background-image"></div><md-button style="position:absolute; top: 0; right: 56px" ng-click="$ctrl.registration.galleryRotation[$index] = ($ctrl.registration.galleryRotation[$index] || 0) - 90" class="md-icon-button margin-half md-primary"><md-icon>rotate_left</md-icon></md-button><md-button style="position:absolute; top: 0; right: 28px" ng-click="$ctrl.registration.galleryRotation[$index] = ($ctrl.registration.galleryRotation[$index] || 0) + 90" class="md-icon-button margin-half md-primary"><md-icon>rotate_right</md-icon></md-button><md-button ng-if="$ctrl.$isRep" style="position:absolute; top: 0; right: 0" ng-click="$ctrl.registration.gallery.splice($index, 1)" class="md-icon-button margin-half md-primary"><md-icon>delete</md-icon></md-button></div></div></div><wc-dialog ng-if="$ctrl.IMAGE_EXPANDED" wc-dialog-template="wc-dialog-template" wc-dialog-fullscreen="false" wc-dialog-modal="false" catch="$ctrl.IMAGE_EXPANDED = false"><wc-dialog-template><img ng-src="{{$ctrl.IMAGE_EXPANDED}}" ng-style="{transform: 'rotate('+$ctrl.EXPANDED_ROTATION+'deg)'}"/></wc-dialog-template></wc-dialog></div></form>