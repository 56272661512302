(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux) {
      this.$states = (
        'AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
        'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
        'WY').split(' ');
      this._ = $window._;
      this.$window = $window;
      this.$firedux = $firedux;
    }
    $invert(array) {
      return this._(array).chain().invert().mapObject(() => {
        return true;
      }).value();
    }
    checkAddress(query) {
      return function (object) {
        return angular.isObject(object) && angular.isObject(object.address) && object.address.address1.indexOf(query) > -1;
      };
    }
    $updateTier(newTier, site, siteId, userId, isAdmin) {
      return Promise.all([
        this.$firedux.ref('sites').child(siteId).child('metadata/tier').set(newTier),
        isAdmin ? Promise.resolve() : this.$firedux.ref('tierUpdates').push({
          timestamp: Date.now(),
          siteId,
          userId,
          oldTier: site.tier,
          newTier
        })
      ]);
    }
    $claimSite(siteId, userId, teamId) {
      return Promise.all([
        this.$firedux.ref('sites').child(siteId).child('metadata/users').child(userId).set(userId),
        this.$firedux.ref('sites').child(siteId).child('metadata/teams').child(teamId).set(teamId)
      ]).then(() => {
        this.$window.history.back();
      });
    }
  }
  angular
    .module('site.detail', [])
    .component('siteDetail', {
      controller: Controller,
      templateUrl: 'root/site/_components/detail/detail-site.comp.html',
      bindings: {
        site: '<',
        approvalId: '<',
        checkAddress: '<',
        isDisabled: '<',
        form: '&'
      }
    });
}());
