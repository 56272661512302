(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('displayAddAction', {
      controller: Controller,
      templateUrl: 'actions/display/add/add-display.action.html',
      bindings: {
        display: '<',
        vendorId: '<',
        then: '&',
        catch: '&'
      }
    });
}());
