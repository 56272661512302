(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.siteId) &&
      (angular.isString(action.productId) || angular.isObject(action.products)) &&
      angular.isObject(action.registration) &&
      angular.isObject(action.registration.agent) &&
      angular.isString(action.registration.agent.displayName) &&
      angular.isString(action.registration.agent.phone) &&
      angular.isNumber(action.registration.date)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux, _) {
    if (action.products) {
      return Promise.all(_.map(_.keys(action.products), productId => saveRegistration({
        siteId: action.siteId,
        productId,
        contact: action.registration.contact,
        displayName: action.registration.agent.displayName,
        phone: action.registration.agent.phone,
        gallery: action.registration.gallery,
        notes: action.registration.notes,
        date: action.registration.date,
        $firedux
      })));
    }
    if (action.productId) {
      return saveRegistration({
        siteId: action.siteId,
        productId: action.productId,
        contact: action.registration.contact,
        displayName: action.registration.agent.displayName,
        phone: action.registration.agent.phone,
        gallery: action.registration.gallery,
        notes: action.registration.notes,
        date: action.registration.date,
        $firedux
      });
    }
  }
  function saveRegistration({siteId, productId, contact, displayName, phone, gallery, notes, date, $firedux}) {
    return $firedux
      .ref('sites')
      .child(siteId)
      .child('products')
      .child(productId)
      .child('registration')
      .set({
        isApproved: true,
        userId: $firedux.auth.uid,
        date,
        notes: notes || '',
        gallery: gallery || null,
        agent: {
          displayName,
          phone
        },
        contact: contact || null
      });
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux, $window) {
    $firedux.reducer({
      trigger: 'SITE.ADD_PRODUCT',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux, $window._) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
