(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('vendor.update', [])
    .component('vendorUpdate', {
      controller: Controller,
      templateUrl: 'root/vendor/update/update-vendor.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('vendor.update', {
          abstract: false,
          url: '/update',
          template: '<vendor-update/>'
        });
    });
}());
