(function () {
  'use strict';
  class Controller {
    constructor($window, $state, $stateParams) {
      this.history = $window.history;
      this.$state = $state;
      this.$stateParams = $stateParams;
    }
    back() {
      this.history.back();
    }
  }
  angular
    .module('supplier.update', [])
    .component('supplierUpdate', {
      controller: Controller,
      templateUrl: 'root/supplier/update/update-supplier.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('supplier.update', {
          abstract: false,
          url: '/update',
          template: '<supplier-update/>'
        });
    });
}());
