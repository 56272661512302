(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux) {
      this._ = $window._;
      this.$firedux = $firedux;
      this.$reviewLengths = {};
    }
    $delete(itemId, object) {
      delete object[itemId];
    }
    $size(obj) {
      const _ = this._;
      return _(obj).size();
    }
    $sort(list, sort) {
      const _ = this._;
      return _(list).chain().map((item, $id) => Object.assign(item, {$id})).sort(item => item[sort]).value().reverse();
    }
    $countReviews(obj, productId, siteId) {
      const _ = this._;
      Promise.all(_.map(_.keys(obj), reviewId => this.$firedux.ref(`reviews/${reviewId}`).once('value').then(s => s.val())))
        .then(reviews => _.filter(reviews, review => review.siteId === siteId))
        .then(reviews => {
          this.$reviewLengths[productId] = reviews.length;
          this.$firedux.$apply();
        });
      return 2;
    }
  }
  angular
    .module('site.products', [])
    .component('siteProducts', {
      controller: Controller,
      templateUrl: 'root/site/_components/products/products-site.comp.html',
      bindings: {
        siteId: '<'
      }
    });
}());
