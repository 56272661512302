(function () {
  'use strict';
  angular
    .module('site', [
      // Components
      'site.detail',
      'site.metadata',
      'site.products',
      'site.productRegistration',
      'site.productMetadata',
      'site.suppliers',
      // Routes
      'site.list',
      'site.add',
      'site.update',
      'site.addProduct',
      'site.addProducts',
      'site.updateProduct',
      'site.addDisplay',
      'site.register'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/site', '/site/list');
      $stateProvider
        .state('site', {
          abstract: false,
          url: '/site?siteId',
          template: '<ui-view/>'
        });
    });
}());
