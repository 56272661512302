(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.siteId) &&
      angular.isString(action.productId) &&
      angular.isArray(action.gallery)
    ) {
      returnable = true;
    } else if (
      angular.isString(action.siteId) &&
      angular.isString(action.productId) &&
      angular.isObject(action.registration) &&
      angular.isObject(action.registration.agent) &&
      angular.isString(action.registration.agent.displayName) &&
      angular.isString(action.registration.agent.phone) &&
      angular.isNumber(action.registration.date)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux, _) {
    return !action.gallery ? $firedux
      .ref('sites')
      .child(action.siteId)
      .child('products')
      .child(action.productId)
      .child('registration')
      .update({
        isApproved: true,
        userId: $firedux.auth.uid,
        date: action.registration.date,
        supplierId: action.registration.supplierId || null,
        notes: action.registration.notes || '',
        gallery: action.registration.gallery || null,
        galleryRotation: action.registration.galleryRotation || null,
        agent: {
          displayName: action.registration.agent.displayName,
          phone: action.registration.agent.phone
        },
        contact: action.registration.contact || null
      }) : $firedux
      .ref('sites')
      .child(action.siteId)
      .child('products')
      .child(action.productId)
      .child('registration/gallery')
      .update((() => {
        return _(action.gallery)
          .chain()
          .map(photoURL => {
            return [
              $firedux.var('UID'),
              photoURL
            ];
          })
          .object()
          .value();
      })());
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux, $window) {
    $firedux.reducer({
      trigger: 'SITE.UPDATE_PRODUCT',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux, $window._) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
