(function () {
  'use strict';
  angular
    .module('user', [
      // Components
      'user.detail',
      // Routes
      'user.list',
      'user.add',
      'user.update'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/user', '/user/list');
      $stateProvider
        .state('user', {
          abstract: false,
          url: '/user',
          template: '<ui-view/>'
        });
    });
}());
