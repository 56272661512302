(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux) {
      this.$firedux = $firedux;
      this._ = $window._;
      this.$window = $window;
    }
    $onChanges() {
      const {_} = this;
      if (_.size(this.productsArray) !== _.size((this.user || {}).products)) {
        this.productsArray = _((this.user || {}).products).chain().map((v, k) => [k, v]).sortBy(i => i[1]).map(i => i[0]).value();
      }
    }
    $updateOrder() {
      const {_} = this;
      this.$window.setTimeout(() => {
        this.$firedux.ref('users').child(this.userId).child('products')
          .set(_.object(
            this.productsArray,
            _.map(this.productsArray, (v, i) => i)
          ));
      }, 500);
    }
    $addProductsFromDisplay(display) {
      const {_} = this;
      _.each((display || {}).products, (item, productId) => {
        if (!this.user.products) {
          this.user.products = {};
        }
        this.user.products[productId] = true;
      });
    }
    $setVendor(vendor) {
      if (!this.user.vendors) {
        this.user.vendors = {};
      }
      if (vendor) {
        this.user.vendors[vendor.key] = true;
      }
      this.selectedVendor = '';
    }
  }
  angular
    .module('user.detail', [])
    .component('userDetail', {
      controller: Controller,
      templateUrl: 'root/user/_components/detail/detail-user.comp.html',
      bindings: {
        form: '&',
        user: '<',
        userId: '<'
      }
    });
}());
