(function () {
  'use strict';
  function isAddress(address) {
    return angular.isString(address.address1) &&
            (angular.isString(address.address2) || !address.address2) &&
            angular.isString(address.city) &&
            angular.isString(address.state) &&
            angular.isString(address.zip);
  }
  function validate(action = {}) {
    let returnable;
    if (
      angular.isObject(action.site) &&
      angular.isString(action.site.displayName) &&
      isAddress(action.site.address)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    let uid = $firedux.var('UID');
    return $firedux
      .ref('sites')
      .child(uid)
      .child('metadata')
      .set({
        isApproved: true,
        userId: $firedux.auth.uid,
        teamId: action.teamId || null,
        displayName: action.site.displayName,
        contactName: action.site.contactName || null,
        contactPhone: action.site.contactPhone || null,
        address: action.site.address,
        tier: action.site.tier,
        tierSet: true,
        notes: angular.isString(action.site.notes) ? action.site.notes : null
      })
      .then(() => {
        return uid;
      });
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'SITE.ADD',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
