(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.approvalType) &&
      angular.isArray(action.batch)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function getRequests(batch) {
    let requests = {};
    angular.forEach(batch, item => {
      requests[item] = false;
    });
    return requests;
  }
  function reducer(action, $firedux) {
    let updatedRequests = getRequests(action.batch);
    return $firedux
      .ref('approval')
      .child(action.approvalType)
      .update(updatedRequests);
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'APPROVAL.DENY',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
