(function () {
  'use strict';
  class Controller {
    constructor() {
    }
    $onChanges() {}
  }
  angular
    .module('table', [])
    .component('tableEl', {
      controller: Controller,
      templateUrl: 'root/_components/table/table.comp.html',
      transclude: {
        toolbar: '?tableToolbar',
        empty: '?tableEmpty'
      },
      bindings: {
        searchPlaceholder: '@',
        data: '<',
        labels: '<'
      }
    });
}());
