(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux) {
      this._ = $window._;
      this.$firedux = $firedux;
    }
    $size(obj) {
      return this._(obj).size();
    }
    $countSites(sites, productId) {
      const {_} = this;
      return Promise.all(_.map(sites, (site, siteId) => this.$firedux.ref('sites').child(siteId).child('metadata/displayName').once('value').then(s => s.val())))
        .then(_.compact).then(_.size).then(size => {
          this.$productList[productId].sites = size;
          this.$firedux.$apply();
        });
    }
  }
  angular
    .module('vendor.products', [])
    .component('vendorProducts', {
      controller: Controller,
      templateUrl: 'root/vendor/_components/products/products-vendor.comp.html',
      bindings: {
        vendorId: '<'
      }
    });
}());
