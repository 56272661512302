(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('home', [])
    .component('homeEl', {
      controller: Controller,
      templateUrl: 'root/home/home.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('home', {
          abstract: false,
          url: '/home',
          template: '<home-el/>'
        });
    });
}());
