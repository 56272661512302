(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('user.add', [])
    .component('userAdd', {
      controller: Controller,
      templateUrl: 'root/user/add/add-user.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('user.add', {
          abstract: false,
          url: '/add?teamId',
          template: '<user-add/>'
        });
    });
}());
