
(function () {
  'use strict';
  class Controller {
    constructor($firedux) {
      this.$firedux = $firedux;
    }
    notifyImport() {
      return this.$firedux
        .ref('import/reviews')
        .set(Date.now())
        .then(() => this.then());
    }
  }
  angular
    .module('actions')
    .component('productUploadReviews', {
      controller: Controller,
      templateUrl: 'actions/product/uploadReviews/uploadReviews-product.action.html',
      bindings: {
        productId: '<',
        catch: '&',
        then: '&'
      }
    });
}());
