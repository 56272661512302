(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('supplier.detail', [])
    .component('supplierDetail', {
      controller: Controller,
      templateUrl: 'root/supplier/_components/detail/detail-supplier.comp.html',
      bindings: {
        form: '&',
        supplier: '<'
      }
    });
}());
