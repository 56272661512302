(function () {
  'use strict';
  class Controller {
    constructor($window, $document, $timeout) {
      this.maps = $window.google.maps;
      this.$document = $document[0];
      this.$timeout = $timeout;
      this.$window = $window;
    }
    getRedirectUrl() {
      this.geocode(this.formatAddress(this.address)).then(formattedAddress => {
        this.$timeout(() => {
          this.$url = `https://www.google.com/maps/place/?@?api=1&q=${formattedAddress}`;
          this.$loading = false;
        }, 100);
      });
    }
    formatAddress(address) {
      if (angular.isObject(address)) {
        return `${address.address1}, ${address.city}, ${address.state} ${address.zip}`;
      }
    }
    geocode(address) {
      let geocoder = new this.maps.Geocoder();
      return new Promise((resolve, reject) => {
        geocoder.geocode({address}, (results, status) => {
          if (status === 'OK') {
            const fa = 'formatted_address';
            resolve(results[0][fa]);
          } else {
            reject(status);
          }
        });
      });
    }
    $redirect() {
      if (!this.$url) {
        this.getRedirectUrl();
        this.$loading = true;
      }
    }
  }
  angular
    .module('streetview', [])
    .component('streetviewEl', {
      controller: Controller,
      templateUrl: 'root/_components/streetview/streetview.comp.html',
      bindings: {
        address: '<'
      }
    });
}());
