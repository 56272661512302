(function () {
  'use strict';
  class Controller {
    constructor($window, $state, $firedux, $stateParams) {
      this.history = $window.history;
      this.$state = $state;
      this.$firedux = $firedux;
      this.$stateParams = $stateParams;
      this._ = $window._;
      this.base = $window.base;
    }
    $back() {
      this.history.back();
    }
    $loadSites() {
      this.$firedux.ref(`products/${this.$productId}`).on('value', snapshot => {
        this.$totalSites = this._(snapshot.child('sites').val()).size();
        this.$totalReviews = this._(snapshot.child('reviews').val()).size();
        this.$progressPercentage = Math.floor(100 * this.$totalReviews / this.$totalSites);
        this.$firedux.$apply();
      });
    }
    $selectTab(selectedTab) {
      this.$state.transitionTo(
        this.$state.current,
        Object.assign(this.$stateParams, {
          selectedTab
        }),
        {
          reload: false,
          notify: false,
          location: 'replace'
        });
    }
    parseInt(num = 0) {
      return parseInt(num, 10);
    }
  }
  angular
    .module('product.update', [])
    .component('productUpdate', {
      controller: Controller,
      templateUrl: 'root/product/update/update-product.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('product.update', {
          abstract: false,
          url: '/?vendorId&productId&siteId&approval&selectedTab&query',
          template: '<product-update/>'
        });
    });
}());
