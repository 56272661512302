(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('display.add', [])
    .component('displayAdd', {
      controller: Controller,
      templateUrl: 'root/display/add/add-display.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('display.add', {
          abstract: false,
          url: '/add?vendorId',
          template: '<display-add/>'
        });
    });
}());
