(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $size(obj) {
      return this._(obj).size();
    }
  }
  angular
    .module('vendor.displays', [])
    .component('vendorDisplays', {
      controller: Controller,
      templateUrl: 'root/vendor/_components/displays/displays-vendor.comp.html',
      bindings: {
        vendorId: '<'
      }
    });
}());
