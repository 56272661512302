(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.displayId) &&
      angular.isString(action.vendorId)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    return Promise.all([
      $firedux
        .ref('displays')
        .child(action.displayId)
        .remove(),
      $firedux
        .ref('vendors')
        .child(action.vendorId)
        .child('displays')
        .child(action.displayId)
        .remove()
    ]);
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'DISPLAY.REMOVE',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
