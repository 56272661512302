<fd-user then="$ctrl.$isAdmin = $data.isAdmin"><then><fd-dispatcher fd-dispatcher-action="{
        type: 'SITE.ADD_PRODUCT',
        productId: $ctrl.productId,
        products: $ctrl.products,
        siteId: $ctrl.siteId,
        registration: $ctrl.registration,
        isAdmin: $ctrl.$isAdmin
      }"><before><wc-progress wc-progress-type="linear"></wc-progress></before><catch><wc-dialog wc-dialog-alert="true" wc-dialog-labels="{
            title: 'There was an error adding the product',
            body: 'Check that the product is not already registered in the site.'
          }" then="$ctrl.catch()"></wc-dialog></catch><then><wc-toast ng-init="$ctrl.then()" wc-toast-message="Product added succesfully"></wc-toast></then></fd-dispatcher></then></fd-user>