(function () {
  'use strict';
  // function isAddress(address) {
  //   return angular.isString(address.address1) &&
  //           (angular.isString(address.address2) || !address.address2) &&
  //           angular.isString(address.city) &&
  //           angular.isString(address.state) &&
  //           angular.isString(address.zip);
  // }
  function validate(action = {}) {
    let returnable;
    if (
      angular.isObject(action.supplier) &&
      angular.isString(action.supplier.displayName)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer({supplier: {displayName, contactName, contactPhone, notes}}, $firedux) {
    let uid = $firedux.var('UID');
    return $firedux
      .ref('suppliers')
      .child(uid)
      .child('metadata')
      .set({
        userId: $firedux.auth.uid,
        displayName,
        contactName: contactName || null,
        contactPhone: contactPhone || null,
        notes: notes || null
      })
      .then(() => uid);
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'SUPPLIER.ADD',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
