(function () {
  'use strict';
  function config($mdThemingProvider, $windowProvider) {
    let greenMap = $mdThemingProvider.extendPalette('teal', {
      300: '#6FB98F',
      400: '#2C7873',
      500: '#004445',
      600: '#021C1E',
      contrastDefaultColor: 'light',
      contrastDarkColors: ['300']
    });
    $mdThemingProvider.definePalette('green-map', greenMap);
    if ($windowProvider.$get().location.origin === 'http://localhost:3000') {
      $windowProvider.$get().base = 'staging';
    } else if ($windowProvider.$get().location.origin === 'http://staging.localhost:3000') {
      $windowProvider.$get().base = 'staging';
    } else if ($windowProvider.$get().location.origin === 'http://production.localhost:3000') {
      $windowProvider.$get().base = 'production';
    } else if ($windowProvider.$get().location.origin === 'https://drip.danielangel.co') {
      $windowProvider.$get().base = 'paul';
    } else if ($windowProvider.$get().location.origin === 'https://drip.webcat.app') {
      $windowProvider.$get().base = 'paul';
    } else if ($windowProvider.$get().location.origin === 'https://aatacdrip.co') {
      $windowProvider.$get().base = 'production';
    } else if ($windowProvider.$get().location.origin === 'https://drip-1e853.firebaseapp.com') {
      $windowProvider.$get().base = 'production';
    } else {
      $windowProvider.$get().base = 'staging';
    }
    if ($windowProvider.$get().base === 'paul') {
      $mdThemingProvider.theme('default')
        .primaryPalette('blue', {
          default: '700',
          'hue-1': '500',
          'hue-2': '600'
        })
        .accentPalette('light-blue', {
          default: '500',
          'hue-1': '100',
          'hue-2': '700'
        });
    } else {
      $mdThemingProvider.theme('default')
        .primaryPalette('deep-orange', {
          default: '700',
          'hue-1': '500',
          'hue-2': '600'
        })
        .accentPalette('light-blue', {
          default: '500',
          'hue-1': '100',
          'hue-2': '700'
        });
    }
    $mdThemingProvider.theme('grey-theme')
      .primaryPalette('grey', {
        default: '400',
        'hue-1': '900',
        'hue-2': '100'
      });
  }
  angular
    .module('app.themes', [])
    .config(config);
}());
