(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('vendor.detail', [])
    .component('vendorDetail', {
      controller: Controller,
      templateUrl: 'root/vendor/_components/detail/detail-vendor.comp.html',
      bindings: {
        form: '&',
        vendor: '<'
      }
    });
}());
