<fd-auth then="$ctrl.$userId = $data.uid"></fd-auth><fd-user then="$ctrl.$isAdmin = $data.isAdmin"></fd-user><fd-ref ng-if="!$ctrl.$approvals.length" fd-ref-path="approval/productUpdate" fd-ref-array="rich" then="$ctrl.$approvals = $data"><before><wc-progress wc-progress-type="linear"></wc-progress></before></fd-ref><approval-approve-action ng-if="$ctrl.APPROVE" type="productUpdate" batch="
    $ctrl.$approvalSelected.length
      ? $ctrl.$approvalSelected
      : $ctrl.$approvalAll" then="
    $ctrl.APPROVE = false;
    $ctrl.$approvalSelected = [];
    $ctrl.$approvalAll = [];" catch="$ctrl.APPROVE = false"></approval-approve-action><approval-deny-action ng-if="$ctrl.DENY" type="productUpdate" batch="$ctrl.$approvalSelected" then="
    $ctrl.DENY = false;
    $ctrl.$approvalSelected = [];
    $ctrl.$approvalAll = [];
    $ctrl.$approvals = []" catch="$ctrl.DENY = false"></approval-deny-action><div flex="flex" ng-if="$ctrl.$approvals.length" class="md-whiteframe-2dp"><md-toolbar md-colors="::{background: 'grey-100'}" class="md-table-toolbar"><div ng-show="$ctrl.$approvalSelected.length" class="md-toolbar-tools"><h2>Selected ({{$ctrl.$approvalSelected.length}})</h2><div flex="flex"></div><md-button ng-if="$ctrl.$approvalSelected.length &amp;&amp; $ctrl.$isAdmin" ng-click="$ctrl.DENY = true">DENY</md-button><md-button ng-if="$ctrl.$approvalSelected.length &amp;&amp; $ctrl.$isAdmin" ng-click="$ctrl.APPROVE = true" class="md-raised md-accent">APPROVE</md-button></div><div ng-hide="$ctrl.$approvalSelected.length" class="md-toolbar-tools"><h2>Product updates</h2><div flex="flex"></div><md-button ng-if="$ctrl.$isAdmin" ng-disabled="!$ctrl.$approvals.length" ng-click="$ctrl.APPROVE = true" class="md-raised md-primary">APPROVE ALL</md-button></div></md-toolbar><md-divider></md-divider><md-table-container ng-init="
      $ctrl.$approvalSelected = [];
      $ctrl.$approvalAll = [];"><!-- VENDORS--><table md-table="md-table" md-row-select="" multiple="" ng-model="$ctrl.$approvalSelected"><thead md-head="md-head"><tr md-row="md-row"><th md-column="md-column"><span>Change date</span></th><th md-column="md-column"><span>Agent name</span></th><th md-column="md-column"><span>Product name</span></th><th md-column="md-column"><span>Fields updated</span></th></tr></thead><tbody md-body="md-body"><tr ng-init="$ctrl.$approvalAll.push(review.key)" md-row="md-row" ng-show="$ctrl.$isAdmin || review.userId === $ctrl.$userId" md-select="review.key" md-auto-select="true" ng-repeat="review in $ctrl.$approvals" class="pointer"><td md-cell="md-cell"><p>{{review.timestamp | date}}</p></td><td md-cell="md-cell"><fd-ref fd-ref-path="users/{{review.userId}}/displayName" then="review.userName = $data"><then><p>{{review.userName}}</p></then></fd-ref></td><td md-cell="md-cell"><fd-ref fd-ref-path="products/{{review.productId}}/metadataApproval/displayName" then="review.productName = $data"><then><p>{{review.productName}}</p></then></fd-ref></td><td md-cell="md-cell"><span ng-repeat="field in review.diff">{{field}}<span ng-hide="$last">,&nbsp;</span></span></td><td md-cell="md-cell"><md-button ui-sref="product.update({productId: review.productId, approval: true})">REVIEW NEW DATA</md-button></td></tr></tbody></table></md-table-container></div>