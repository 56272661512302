(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('product.metadata', [])
    .component('productMetadata', {
      controller: Controller,
      templateUrl: 'root/product/_components/metadata/metadata-product.comp.html',
      bindings: {
        form: '&',
        productId: '<'
      }
    });
}());
