(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this._ = $window._;
    }
    $save(teamVendors, teamId) {
      const {_, $firedux} = this;
      $firedux.ref('teams').child(teamId).child('vendors').set(_.mapObject(teamVendors, i => i || null));
    }
  }
  angular
    .module('team.update', [])
    .component('teamUpdate', {
      controller: Controller,
      templateUrl: 'root/team/update/update-team.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('team.update', {
          abstract: false,
          url: '/update?teamId',
          template: '<team-update/>'
        });
    });
}());
