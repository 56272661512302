(function () {
  'use strict';
  angular
    .module('vendor', [
      // Components
      'vendor.detail',
      'vendor.metadata',
      'vendor.products',
      'vendor.displays',
      // Routes
      'vendor.list',
      'vendor.add',
      'vendor.update'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/vendor', '/vendor/list');
      $stateProvider
        .state('vendor', {
          abstract: false,
          url: '/vendor?vendorId',
          template: '<ui-view/>'
        });
    });
}());
