(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this.history = $window.history;
    }
    $back() {
      this.history.back();
    }
  }
  angular
    .module('product.add', [])
    .component('productAdd', {
      controller: Controller,
      templateUrl: 'root/product/add/add-product.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('product.add', {
          abstract: false,
          url: '/add?vendorId&siteId',
          template: '<product-add/>'
        });
    });
}());
