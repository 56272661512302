(function () {
  'use strict';
  angular
    .module('drip', [
      // DEPENDENCIES
      'md.data.table',
      'chart.js',
      'ui.sortable',
      // CONFIG
      'app.firedux',
      'app.themes',
      'app.routes',
      // APP
      'root'
    ]);
}());
