(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('display.metadata', [])
    .component('displayMetadata', {
      controller: Controller,
      templateUrl: 'root/display/_components/metadata/metadata-display.comp.html',
      bindings: {
        displayId: '<'
      }
    });
}());
