(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux, $sce, $http) {
      this.$window = $window;
      this.$firedux = $firedux;
      this.$sce = $sce;
      this.$http = $http;
    }
    $onInit() {
      if (this.$window.firebase.auth().currentUser) {
        this.buildUrl(this.$window.firebase.auth().currentUser);
      } else {
        let unsubscribe = this.$window.firebase.auth().onAuthStateChanged(currentUser => {
          if (currentUser) {
            unsubscribe();
            this.buildUrl(currentUser);
          }
        });
      }
    }
    buildUrl(currentUser) {
      const uid = currentUser.uid;
      currentUser.getToken()
        .then(t =>
          this.$http.get(`https://us-central1-drip-1e853.cloudfunctions.net/godLogin?uid=${uid}`, {
            headers: {
              Authorization: `Bearer ${t}`
            }
          })
        )
        .then(({data: token}) => {
          this.url = this.$sce.trustAsResourceUrl(`https://drip.igniteit.io/siteWizard?token=${token}`);
          this.$firedux.$apply();
        });
    }
  }
  angular
    .module('site.register', [])
    .component('siteRegister', {
      controller: Controller,
      templateUrl: 'root/site/register/register-site.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('site.register', {
          abstract: false,
          url: '/register',
          template: '<site-register/>'
        });
    });
}());
