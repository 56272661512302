(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.productId) &&
      angular.isObject(action.product) &&
      angular.isString(action.product.displayName) &&
      angular.isNumber(action.product.price) &&
      angular.isString(action.product.categoryId)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    return $firedux
      .ref('products')
      .child(action.productId)
      .child('metadata')
      .update({
        isApproved: true,
        vendorId: action.product.vendorId || null,
        displayName: action.product.displayName,
        price: action.product.price,
        categoryId: action.product.categoryId,
        display: action.product.display || null,
        caseCount: action.product.caseCount || null,
        unitsPerSite: action.product.unitsPerSite || null,
        distributorPrice: action.product.distributorPrice || null,
        marketing: action.product.marketing || null,
        tiers: action.product.tiers || null,
        notes: angular.isString(action.product.notes) ? action.product.notes : null
      });
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'PRODUCT.UPDATE',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
