(function () {
  'use strict';
  class Controller {
  }
  angular
    .module('category.reviews', [])
    .component('categoryReviews', {
      controller: Controller,
      templateUrl: 'root/category/_components/reviews/reviews-category.comp.html',
      bindings: {
        form: '&',
        categoryId: '<'
      }
    });
}());
