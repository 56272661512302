<wc-dialog wc-dialog-template="wc-dialog-template" wc-dialog-fullscreen="false"><wc-dialog-template><div class="padding"><div class="md-title">Adding new user</div><div class="md-body-1">Please wait until the database is updated</div></div><fd-dispatcher fd-dispatcher-action="{
        type: 'USER.ADD',
        email: $ctrl.credentials.email,
        password: $ctrl.credentials.password,
        displayName: $ctrl.user.displayName,
        role: $ctrl.user.role,
        vendorId: $ctrl.user.vendorId,
        teamId: $ctrl.teamId,
        phone: $ctrl.user.phone,
        company: $ctrl.user.company,
        disabled: $ctrl.user.disabled,
        products: $ctrl.user.products,
        displays: $ctrl.user.displays
      }"><before><wc-progress wc-progress-type="linear"></wc-progress></before><catch><wc-toast ng-init="$ctrl.catch()" wc-toast-message="Error adding user"></wc-toast></catch><then><wc-toast ng-init="$ctrl.then()" wc-toast-message="User added successfully">   </wc-toast></then></fd-dispatcher></wc-dialog-template></wc-dialog>