(function () {
  'use strict';
  class Controller {
    constructor($firedux, $mdSidenav, $window, $http) {
      this.$firedux = $firedux;
      this.$mdSidenav = $mdSidenav;
      this.base = $window.base;
      this.$http = $http;
    }
    $toggleSidenav(sidenavId) {
      this.$mdSidenav(sidenavId).toggle();
    }
    $getUser(userId) {
      this.$http.get(`https://us-central1-drip-1e853.cloudfunctions.net/getUserData?userId=${userId}&base=${this.base}`)
        .then(({data}) => {
          this.$repData = data;
        });
    }
    $logout() {
      this.$firedux.logout();
    }
  }
  angular
    .module('toolbar', [])
    .component('toolbarEl', {
      controller: Controller,
      templateUrl: 'root/_components/toolbar/toolbar.comp.html'
    });
}());
