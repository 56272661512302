(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('vendor.metadata', [])
    .component('vendorMetadata', {
      controller: Controller,
      templateUrl: 'root/vendor/_components/metadata/metadata-vendor.comp.html',
      bindings: {
        vendorId: '<'
      }
    });
}());
