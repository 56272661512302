
(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux) {
      this._ = $window._;
      this.firebase = $window.firebase;
      this.$firedux = $firedux;
      this.$apply = () => $firedux.$apply();
    }
    processUrls(approvals) {
      const {_, firebase, $apply} = this;
      this.$urls = {};
      _.each(approvals, (date, userId) => firebase.storage()
        .ref(`/uploadRequests/${userId}/sitesData.xlsx`)
        .getDownloadURL()
        .then((url) => {
          this.$urls[userId] = url;
        })
        .then($apply));
    }
    notifyImport(userId) {
      return this.$firedux
        .ref('import/sites')
        .set({
          userId
        })
        .then(() => this.$firedux.ref(`/importForApproval/sites/${userId}`).remove());
    }
  }
  angular
    .module('approval.uploadedSites', [])
    .component('approvalUploadedSites', {
      controller: Controller,
      templateUrl: 'root/approval/_components/uploadedSites/uploadedSites-approval.comp.html',
      bindings: {}
    });
}());
