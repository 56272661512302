(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('login', [])
    .component('loginEl', {
      controller: Controller,
      templateUrl: 'root/login/login.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('login', {
          abstract: false,
          url: '/login',
          template: '<login-el/>'
        });
    });
}());
