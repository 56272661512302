(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux) {
      this._ = $window._;
      this.$firedux = $firedux;
      this.$autocompleteSites = [];
    }
    $onInit() {
      if (this.review) {
        this.review.date = this.review.date || Date.now();
        this.review.images = this.review.images || [];
      }
    }
    newDate(date = Date.now()) {
      return new Date(date);
    }
    $addImage(url) {
      this.review.images.push(url);
    }
    $getSites(sites) {
      const {_} = this,
          siteIds = _.keys(sites),
          promises = _.map(siteIds, siteId => this.$firedux.ref(`sites/${siteId}/metadata`).once('value').then(s => Object.assign(s.val() || {}, {key: siteId})));
      Promise.all(promises).then(results => {
        let autocompleteSites = [];
        _.each(results, site => {
          if (this.$isRep || site.userId === this.$userId) {
            autocompleteSites.push(site);
          }
        });
        this.$autocompleteSites = autocompleteSites;
        this.$sites = _.object(_.map(results, 'key'), results);
        this.$firedux.$apply();
      });
    }
    // $filterSites(list, query) {
    //   return this._.filter(list, {
    //     displayName: query
    //   });
    // }
  }
  angular
    .module('review.detail', [])
    .component('reviewDetail', {
      controller: Controller,
      templateUrl: 'root/review/_components/detail/detail-review.comp.html',
      bindings: {
        form: '&',
        review: '<',
        productId: '<'
      }
    });
}());
