(function () {
  'use strict';
  class Controller {
    newDate(date = new Date()) {
      return new Date(date);
    }
  }
  angular
    .module('site.productRegistration', [])
    .component('siteProductRegistration', {
      controller: Controller,
      templateUrl: 'root/site/_components/productRegistration/productRegistration-site.comp.html',
      bindings: {
        siteId: '<',
        registration: '<',
        isApproval: '<'
      }
    });
}());
