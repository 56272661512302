(function () {
  'use strict';
  class Controller {
    constructor($firedux, $state, $window) {
      this.$firedux = $firedux;
      this.$state = $state;
      this.$window = $window;
      this.$query = this.$window.currentQuery || '';
      this._ = $window._;
    }
    $onInit() {
      this.$position = [];
      if (this.$window.navigator.geolocation) {
        const receivePosition = position => {
          this.$position = [position.coords.latitude, position.coords.longitude, position.coords.accuracy];
          this.$sites = this.$processSites(this.$sites);
          this.$firedux.$apply();
        };
        this.$window.navigator.geolocation.watchPosition(receivePosition);
        this.$window.navigator.geolocation.getCurrentPosition(receivePosition);
      }
    }
    $goToSite(siteId) {
      this.$window.currentQuery = this.$query;
      this.$state.go('site.update', {siteId});
    }
    $count(obj) {
      return this._.size(obj);
    }
    $filterSites(sites, teamId, isAdmin, isSubadmin, userId) {
      const filteredSites = this._.filter(sites, site => isSubadmin ? site.metadata.teamId === teamId || (site.metadata.teams || {})[teamId] : site.metadata.userId === userId || (site.metadata.users || {})[userId]);
      let returnable = isAdmin ? sites || [] : filteredSites,
          result;
      this.$totalSites = returnable.length;
      result = this.$sort(returnable, this.$currentSorting || 'metadata.displayName');
      return this.$shouldReverse ? result.reverse() : result;
    }
    notifyImport(userId, isAdmin) {
      return this.$firedux
        .ref(isAdmin ? 'import/sites' : 'importForApproval/sites/' + userId)
        .set(Date.now());
    }
    cancelImport(userId) {
      return this.$firedux
        .ref('importForApproval/sites/' + userId)
        .remove();
    }
    siteFilter(query) {
      function hasText(text) {
        return `${text}`.toLowerCase().indexOf(query.toLowerCase()) > -1;
      }
      return function (item) {
        const metadata = item.metadataApproval || item.metadata || {},
            address = metadata.address || {};
        return !query ||
          hasText(metadata.displayName) ||
          hasText(metadata.contactName) ||
          hasText(metadata.contactPhone) ||
          hasText(address.address1) ||
          hasText(address.address2) ||
          hasText(address.city);
      };
    }
    $sort(list, val) {
      const {_} = this;
      return _.sortBy(list, data => `${this.$get(data, val)}`.replace(' ', ''));
    }
    $get(data, val) {
      const {_} = this;
      let path = (val || '').split('.');
      return _.reduce(path, (memo, child) => _.isObject(memo) && child ? memo[child] : memo, data);
    }
    $processSites(sites) {
      const {_} = this;
      return _.compact(_.map(sites.reverse(), site => {
        if (site && site.metadata) {
          site.metadata.distance = site.metadata.location ? `${this.$distance([site.metadata.location.lat, site.metadata.location.lng], this.$position)}mi` : 'z';
        }
        return site;
      }));
    }
    $distance([lat1, lon1], [lat2, lon2]) {
      var R = 6371,
          dLat = (lat2 - lat1) * Math.PI / 180,
          dLon = (lon2 - lon1) * Math.PI / 180,
          a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2),
          c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)),
          d = R * c;
      return lat2 && lon2 ? Math.round(10 * d / 1.609) / 10 : '--';
    }
  }
  angular
    .module('site.list', [])
    .component('siteList', {
      controller: Controller,
      templateUrl: 'root/site/list/list-site.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('site.list', {
          abstract: false,
          url: '/list',
          template: '<site-list/>'
        });
    });
}());
