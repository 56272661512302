(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('vendorRemoveAction', {
      controller: Controller,
      templateUrl: 'actions/vendor/remove/remove-vendor.action.html',
      bindings: {
        vendorId: '<',
        then: '&',
        catch: '&'
      }
    });
}());
