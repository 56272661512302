(function () {
  'use strict';
  class Controller {
    constructor($window, $state, $stateParams, $firedux) {
      this._ = $window._;
      this.$state = $state;
      this.$firedux = $firedux;
      this.$stateParams = $stateParams;
      this.$sites = {};
    }
    $toArray(obj) {
      return this._(obj).toArray();
    }
    $updateSearch(query) {
      this.$state.transitionTo('product.update', Object.assign(this.$stateParams, {
        query
      }, {
        reload: false,
        notify: false,
        location: 'replace'
      }));
    }
    $go(site) {
      this.$state.go('site.update', {siteId: site.key, productId: this.productId});
    }
    $loadSites() {
      const {_} = this;
      this.$firedux.ref(`products/${this.productId}/sites`).on('value', snapshot => {
        const siteIds = _.keys(snapshot.val());
        Promise
          .all(_.map(siteIds, siteId => {
            if (this.$isRep) {
              return this.$firedux.ref(`sites/${siteId}`).once('value').then(snap => {
                return [
                  siteId,
                  Object.assign({key: siteId}, snap.child('metadataApproval').val() || snap.child('metadata').val() || {}),
                  snap.child('products').child(this.productId).child('registration/userId').val()
                ];
              });
            } else if (this.$isVendor) {
              return this.$firedux.ref(`sites/${siteId}/metadata`).once('value').then(snap => {
                return [siteId, Object.assign({key: siteId}, snap.val() || {})];
              });
            }
          }))
          .then(sites => {
            const sitesWithName = _.filter(sites, ([, {displayName}]) => displayName);
            return _.filter(sitesWithName, ([, {teamId, teams}, registrationUserId]) => {
              const isAdmin = this.$isAdmin,
                  isVendor = this.$isVendor,
                  isSubadmin = this.$isSubadmin ? _.includes(_.keys(teams), this.$teamId) || teamId === this.$teamId : false,
                  isRep = registrationUserId === this.$userId;
              return isAdmin || isVendor || isSubadmin || isRep;
            });
          })
          .then(_.object)
          .then(data => {
            this.$sites = data;
            this.$totalSites = this._.size(data);
            this.$firedux.$apply();
          });
      });
    }
  }
  angular
    .module('product.sites', [])
    .component('productSites', {
      controller: Controller,
      templateUrl: 'root/product/_components/sites/sites-product.comp.html',
      bindings: {
        productId: '<'
      }
    });
}());
