(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.siteId) &&
      angular.isString(action.productId) &&
      angular.isObject(action.review) &&
      angular.isNumber(action.review.amount) &&
      angular.isNumber(action.review.date)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    const uid = $firedux.var('UID');
    return $firedux
      .ref('reviews')
      .child(uid)
      .set({
        timestamp: $firedux.var('TIMESTAMP'),
        isApproved: true,
        productId: action.productId,
        siteId: action.siteId,
        userId: $firedux.auth.uid,
        review: {
          gallery: action.gallery || [],
          amount: action.review.amount,
          date: action.review.date,
          notes: action.review.notes || ''
        }
      });
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'REVIEW.ADD',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
