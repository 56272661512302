(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('vendor.add', [])
    .component('vendorAdd', {
      controller: Controller,
      templateUrl: 'root/vendor/add/add-vendor.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('vendor.add', {
          abstract: false,
          url: '/add',
          template: '<vendor-add/>'
        });
    });
}());
