(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.email) &&
      angular.isString(action.password) &&
      angular.isString(action.displayName) &&
      angular.isString(action.role) &&
      angular.isString(action.phone)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    return $firedux
      .ref('users/ADD')
      .set({
        email: action.email,
        password: action.password,
        displayName: action.displayName,
        role: action.teamId && action.role === 'admin' ? 'subadmin' : action.role,
        phone: action.phone,
        teamId: action.teamId,
        company: action.company || null,
        vendorId: angular.isString(action.vendorId) ? action.vendorId : null,
        repId: angular.isString((action.user || {}).repId) ? action.user.repId : null,
        products: action.products || null,
        displays: action.displays || null
      });
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'USER.ADD',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
