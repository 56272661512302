(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('approvalDenyAction', {
      controller: Controller,
      templateUrl: 'actions/approval/deny/deny-approval.action.html',
      bindings: {
        type: '@',
        batch: '<',
        then: '&',
        catch: '&'
      }
    });
}());
