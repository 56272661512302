(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isObject(action.display)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer({displayId, display: {metadata: {vendorId, displayName, notes}, products}}, $firedux) {
    return $firedux
      .ref('displays')
      .child(displayId)
      .update({
        metadata: {
          vendorId,
          displayName,
          notes: notes || null
        },
        products: products || null
      });
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'DISPLAY.UPDATE',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
