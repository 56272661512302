(function () {
  'use strict';
  class Controller {
    constructor($mdSidenav) {
      this.$mdSidenav = $mdSidenav;
    }
    $toggle() {
      this.$mdSidenav('sidenav').toggle();
    }
  }
  angular
    .module('sidenav', [])
    .component('sidenavEl', {
      controller: Controller,
      templateUrl: 'root/_components/sidenav/sidenav.comp.html'
    });
}());
