(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('userAdminPasswordAction', {
      controller: Controller,
      templateUrl: 'actions/user/adminPassword/adminPassword-user.action.html',
      bindings: {
        userId: '<',
        then: '&',
        catch: '&'
      }
    });
}());
