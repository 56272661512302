(function () {
  'use strict';
  class Controller {
    constructor($window, $mdMedia, $scope) {
      this._ = $window._;
      $scope.$watch(() => $mdMedia('lg'), isLarge => {
        $scope.isLarge = isLarge;
      });
    }
    $delete(itemId, object) {
      delete object[itemId];
    }
    $size(obj) {
      const _ = this._;
      return _(obj).size();
    }
    updateSuppliers(suppliers) {
      return this._.object(suppliers, this._(suppliers).map(() => true));
    }
    addSupplier(newSupplierId, suppliers) {
      const _ = this._;
      return _(suppliers).chain()
        .keys()
        .union([newSupplierId])
        .reduce((memo, supplierId) => {
          memo[supplierId] = true;
          return memo;
        }, {})
        .value();
    }
    removeSupplier(oldSupplierId, suppliers) {
      const _ = this._;
      return _(suppliers).chain()
        .keys()
        .difference([oldSupplierId])
        .reduce((memo, supplierId) => {
          memo[supplierId] = true;
          return memo;
        }, {})
        .value();
    }
  }
  angular
    .module('site.suppliers', [])
    .component('siteSuppliers', {
      controller: Controller,
      templateUrl: 'root/site/_components/suppliers/suppliers-site.comp.html',
      bindings: {
        siteId: '<'
      }
    });
}());
