(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this.$window = $window;
    }
    newDate(date = new Date()) {
      return new Date(date);
    }
    $deleteRegistration() {
      const {siteId, productId} = this;
      Promise.all([
        this.$firedux.ref('sites').child(siteId).child('products').child(productId).remove(),
        this.$firedux.ref('products').child(productId).child('sites').child(siteId).remove()
      ]).then(() => {
        this.$window.history.back();
      });
    }
  }
  angular
    .module('site.productMetadata', [])
    .component('siteProductMetadata', {
      controller: Controller,
      templateUrl: 'root/site/_components/productMetadata/productMetadata-site.comp.html',
      bindings: {
        siteId: '<',
        productId: '<'
      }
    });
}());
