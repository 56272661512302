(function () {
  'use strict';
  function reducer(action, $firedux) {
    return $firedux
      .ref('approval')
      .set(null);
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'APPROVAL.CLEAR',
      reducer: function (action) {
        return reducer(action, $firedux);
      }
    });
  }
}());
