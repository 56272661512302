(function () {
  'use strict';
  angular
    .module('category', [
      // Components
      'category.reviews',
      // Routes
      'category.list',
      'category.update'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/category', '/category/list');
      $stateProvider
        .state('category', {
          abstract: false,
          url: '/category',
          template: '<ui-view/>'
        });
    });
}());
