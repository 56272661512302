(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.userId) &&
      angular.isObject(action.user) &&
      angular.isString(action.user.displayName) &&
      angular.isString(action.user.role) &&
      angular.isString(action.user.phone)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux, _) {
    return $firedux
      .ref('users')
      .child(action.userId)
      .update({
        displayName: action.user.displayName,
        role: action.user.role,
        phone: action.user.phone,
        disabled: !!action.user.disabled,
        company: action.user.company || null,
        vendorId: angular.isString(action.user.vendorId) ? action.user.vendorId : null,
        vendors: action.user.vendors || null,
        repId: angular.isString(action.user.repId) ? action.user.repId : null,
        products: _.object(_.map(_.keys(action.user.products), (p, i) => [p, i])),
        displays: action.user.displays || null
      });
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux, $window) {
    $firedux.reducer({
      trigger: 'USER.UPDATE',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux, $window._) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
