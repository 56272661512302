(function () {
  'use strict';
  class Controller {
    constructor() {
      this.$states = (
        'AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
        'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
        'WY').split(' ');
    }
  }
  angular
    .module('site.metadata', [])
    .component('siteMetadata', {
      controller: Controller,
      templateUrl: 'root/site/_components/metadata/metadata-site.comp.html',
      bindings: {
        siteId: '<'
      }
    });
}());
