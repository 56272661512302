(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $invert(array) {
      return this._(array).chain().invert().mapObject(() => {
        return true;
      }).value();
    }
  }
  angular
    .module('product.detail', [])
    .component('productDetail', {
      controller: Controller,
      templateUrl: 'root/product/_components/detail/detail-product.comp.html',
      bindings: {
        form: '&',
        approvalId: '<',
        product: '<'
      }
    });
}());
