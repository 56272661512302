(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('approval.registerProductSite', [])
    .component('approvalRegisterProductSite', {
      controller: Controller,
      templateUrl: 'root/approval/_components/registerProductSite/registerProductSite-approval.comp.html',
      bindings: {}
    });
}());
