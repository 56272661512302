(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('displayRemoveAction', {
      controller: Controller,
      templateUrl: 'actions/display/remove/remove-display.action.html',
      bindings: {
        displayId: '<',
        vendorId: '<',
        then: '&',
        catch: '&'
      }
    });
}());
