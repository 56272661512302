(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this.history = $window.history;
    }
    $back() {
      this.history.back();
    }
  }
  angular
    .module('site.updateProduct', [])
    .component('siteUpdateProduct', {
      controller: Controller,
      templateUrl: 'root/site/updateProduct/updateProduct-site.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('site.updateProduct', {
          abstract: false,
          url: '/updateProduct?productId&approval',
          template: '<site-update-product/>'
        });
    });
}());
