(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.siteId) &&
      angular.isObject(action.suppliers)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    return $firedux
      .ref('sites')
      .child(action.siteId)
      .child('suppliers')
      .set(action.suppliers);
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'SITE.UPDATE_SUPPLIERS',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
