(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux) {
      this.history = $window.history;
      this.$firedux = $firedux;
      this._ = $window._;
    }
    $back() {
      this.history.back();
    }
    validateTiers(siteTier, productTiers) {
      return siteTier && productTiers ? productTiers[siteTier] : true;
    }
    $filterProducts(products, teamId, isAdmin, isRep) {
      return isAdmin || isRep ? products : this._.filter(products, product => product.metadata.teamId === teamId);
    }
    $sendRequest({siteId, productId, userId}) {
      this.$firedux.ref('/invalidTiers').push({
        siteId,
        productId,
        userId,
        timestamp: Date.now()
      });
    }
  }
  angular
    .module('site.addProduct', [])
    .component('siteAddProduct', {
      controller: Controller,
      templateUrl: 'root/site/addProduct/addProduct-site.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('site.addProduct', {
          abstract: false,
          url: '/addProduct?productId',
          template: '<site-add-product/>'
        });
    });
}());
