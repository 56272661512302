(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.displayName)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    let uid = $firedux.var('UID');
    return $firedux
      .ref('categories')
      .child(uid)
      .child('metadata/displayName')
      .set(action.displayName);
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'CATEGORY.ADD',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
