(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('supplierUpdateAction', {
      controller: Controller,
      templateUrl: 'actions/supplier/update/update-supplier.action.html',
      bindings: {
        supplier: '<',
        supplierId: '<',
        then: '&',
        catch: '&'
      }
    });
}());
