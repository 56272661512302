(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('site.add', [])
    .component('siteAdd', {
      controller: Controller,
      templateUrl: 'root/site/add/add-site.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('site.add', {
          abstract: false,
          url: '/add',
          template: '<site-add/>'
        });
    });
}());
