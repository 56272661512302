(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this.history = $window.history;
    }
    $back() {
      this.history.back();
    }
  }
  angular
    .module('review.add', [])
    .component('reviewAdd', {
      controller: Controller,
      templateUrl: 'root/review/add/add-review.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('review.add', {
          abstract: false,
          url: '/add?productId&vendorId&siteId',
          template: '<review-add/>'
        });
    });
}());
