(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $count(obj) {
      return this._(obj).size();
    }
  }
  angular
    .module('team.list', [])
    .component('teamList', {
      controller: Controller,
      templateUrl: 'root/team/list/list-team.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('team.list', {
          abstract: false,
          url: '/list',
          template: '<team-list/>'
        });
    });
}());
