(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $size(obj) {
      return this._(obj).size();
    }
  }
  angular
    .module('display.products', [])
    .component('displayProducts', {
      controller: Controller,
      templateUrl: 'root/display/_components/products/products-display.comp.html',
      bindings: {
        displayId: '<'
      }
    });
}());
