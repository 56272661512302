(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $count(obj) {
      return this._(obj).size();
    }
  }
  angular
    .module('category.list', [])
    .component('categoryList', {
      controller: Controller,
      templateUrl: 'root/category/list/list-category.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('category.list', {
          abstract: false,
          url: '/list?embedded',
          template: '<category-list/>'
        });
    });
}());
