(function () {
  'use strict';
  class Controller {
    constructor($mdSidenav, $window) {
      this.$mdSidenav = $mdSidenav;
      this.base = $window.base;
      if ($window.location.href.indexOf('embedded') !== -1) {
        this.$embedded = true;
      }
    }
    $toggleSidenav(sidenavId) {
      this.$mdSidenav(sidenavId).toggle();
    }
  }
  angular
    .module('root', [
      // Components
      'toolbar',
      'sidenav',
      'streetview',
      'table',
      // Routes
      'login',
      'site',
      'user',
      'vendor',
      'product',
      'review',
      'approval',
      'category',
      'home',
      'supplier',
      'display',
      'team'
    ])
    .component('rootEl', {
      controller: Controller,
      templateUrl: 'root/root.comp.html'
    });
}());
