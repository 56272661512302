(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('category.update', [])
    .component('categoryUpdate', {
      controller: Controller,
      templateUrl: 'root/category/update/update-category.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('category.update', {
          abstract: false,
          url: '/update?categoryId',
          template: '<category-update/>'
        });
    });
}());
