(function () {
  'use strict';
  angular
    .module('actions', [
      'ng-webcat'
    ]);
  angular
    .module('reducers', [
      'ng-webcat'
    ]);
  angular
    .module('app.firedux', [
      'actions',
      'reducers',
      'ng-webcat'
    ])
    .run(run);
  function run($firedux, $window) {
    $firedux.init({
      config: {
        apiKey: 'AIzaSyBQPkd0URUW2VeUokuCch0OtUmUw4P3Izc',
        authDomain: 'drip-1e853.firebaseapp.com',
        databaseURL: 'https://drip-1e853.firebaseio.com',
        projectId: 'drip-1e853',
        storageBucket: 'drip-1e853.appspot.com'
      },
      base: $window.base
    });
  }
}());
