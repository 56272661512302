<wc-ui-params then="
    $ctrl.$siteId=$data.siteId;
    $ctrl.$vendorId=$data.vendorId;
    $ctrl.$productId=$data.productId;
    $ctrl.$paramsReady = true;"></wc-ui-params><wc-ui-params then="$ctrl.$productId=$data.productId; $ctrl.$paramsReady = true;"></wc-ui-params><wc-ui-redirect ng-if="!$ctrl.$productId &amp;&amp; $ctrl.$paramsReady" wc-ui-redirect-sref="product.list"></wc-ui-redirect><fd-ref ng-if="$ctrl.$productId" fd-ref-path="products/{{$ctrl.$productId}}/metadata" then="$ctrl.$product = $data"></fd-ref><review-add-action ng-if="$ctrl.CREATE" review="$ctrl.$review" gallery="$ctrl.$gallery" product-id="$ctrl.$productId" then="$ctrl.CREATE = false; $ctrl.PRODUCT = true" catch="$ctrl.CREATE = false"></review-add-action><site-update-product-action ng-if="$ctrl.PRODUCT" product-id="$ctrl.$productId" site-id="$ctrl.$review.siteId" gallery="$ctrl.$gallery" then="$ctrl.PRODUCT = false; $ctrl.$back()" catch="$ctrl.PRODUCT = false"></site-update-product-action><md-toolbar class="md-hue-2"><div class="md-toolbar-tools"><md-button ng-if="$ctrl.$productId" ng-click="$ctrl.$back()" class="md-icon-button"><md-icon>arrow_back</md-icon></md-button><div layout="column"> <div class="md-body-1">Product: {{$ctrl.$product.displayName}}</div><h2>New review</h2></div><div flex="flex"></div><md-button ng-click="$ctrl.$info = true" class="md-icon-button opacity5"><wc-toast ng-if="$ctrl.$info" ng-init="$ctrl.$info = false" wc-toast-message="Add the info from the new review and then click on CREATE"></wc-toast><md-icon>help</md-icon></md-button></div></md-toolbar><div layout="row" layout-align="center"><div ng-init="$ctrl.$review = {}" class="margin padding-half md-whiteframe-2dp container"><review-detail review="$ctrl.$review" product-id="$ctrl.$productId" form="$ctrl.$form = $form"></review-detail><div class="padding-half"><div layout="row" layout-align="start center" class="md-body-2 padding-v-half"><b>IMAGES</b><div flex="flex"></div><md-button class="md-icon-button"><md-icon>add_a_photo</md-icon><wc-upload wc-upload-dimensions="{
              width: 800,
              height: 600
            }" then="$ctrl.$photoFile = $file"><then><fd-upload ng-if="$ctrl.$photoFile" fd-upload-file="$ctrl.$photoFile" before="$ctrl.percentage = $progress" then="$ctrl.$gallery.push($data.url)"><before><wc-dialog wc-dialog-progress="{{$ctrl.percentage}}" wc-dialog-labels="{
                      title: 'Uploading (' + $ctrl.percentage + '%)',
                      body: 'Please wait until image is fully uploaded.'
                    }"></wc-dialog></before><catch><wc-toast wc-toast-message="Error uploading image"></wc-toast></catch></fd-upload></then></wc-upload></md-button></div><div ng-init="$ctrl.$gallery = $ctrl.$gallery || []" layout="row" layout-align="center" class="margin-quarter"><div flex="50"><div ng-repeat="image in $ctrl.$gallery" ng-style="{ backgroundImage: 'url('+image+')' }" class="background-image ratio-4-3 margin-quarter"></div></div></div></div><div layout="row"><div flex="flex"></div><md-button ng-click="$ctrl.$back()">CANCEL</md-button><md-button ng-disabled="$ctrl.$form.$invalid" ng-click="$ctrl.CREATE = true" class="md-raised md-primary">CREATE</md-button></div></div></div>