(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $size(obj) {
      return this._(obj).size();
    }
  }
  angular
    .module('supplier.products', [])
    .component('supplierProducts', {
      controller: Controller,
      templateUrl: 'root/supplier/_components/products/products-supplier.comp.html',
      bindings: {
        supplierId: '<'
      }
    });
}());
