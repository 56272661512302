(function () {
  'use strict';
  angular
    .module('display', [
      // Components
      'display.detail',
      'display.metadata',
      'display.products',
      // Routes
      'display.list',
      'display.add',
      'display.update'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/display', '/display/list');
      $stateProvider
        .state('display', {
          abstract: false,
          url: '/display?displayId',
          template: '<ui-view/>'
        });
    });
}());
