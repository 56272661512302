(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this.history = $window.history;
    }
    $back() {
      this.history.back();
    }
  }
  angular
    .module('site.addDisplay', [])
    .component('siteAddDisplay', {
      controller: Controller,
      templateUrl: 'root/site/addDisplay/addDisplay-site.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('site.addDisplay', {
          abstract: false,
          url: '/addDisplay?displayId',
          template: '<site-add-display/>'
        });
    });
}());
