(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('approval.list', [])
    .component('approvalList', {
      controller: Controller,
      templateUrl: 'root/approval/list/list-approval.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('approval.list', {
          abstract: false,
          url: '/list',
          template: '<approval-list/>'
        });
    });
}());
