(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('reviewAddAction', {
      controller: Controller,
      templateUrl: 'actions/review/add/add-review.action.html',
      bindings: {
        review: '<',
        gallery: '<',
        productId: '<',
        then: '&',
        catch: '&'
      }
    });
}());
