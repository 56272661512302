(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    return returnable * action;
  }
  function reducer(action, $firedux) {
    return $firedux
      .ref('products')
      .child(action.productId)
      .child('metadata')
      .update(action.null);
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'PRODUCT.UPLOAD_REVIEWS',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
