(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('siteAddProductAction', {
      controller: Controller,
      templateUrl: 'actions/site/addProduct/addProduct-site.action.html',
      bindings: {
        siteId: '<',
        registration: '<',
        productId: '<',
        products: '<',
        then: '&',
        catch: '&'
      }
    });
}());
