
(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux) {
      this._ = $window._;
      this.firebase = $window.firebase;
      this.$firedux = $firedux;
      this.$apply = () => $firedux.$apply();
    }
    $getTiers(tiers) {
      return this._(tiers).chain().pairs().filter('1').map('0').value().join(', ');
    }
    $approve(itemId) {
      return this.$firedux.ref('invalidTiers').child(itemId).set(null);
    }
    $reject(itemId, item) {
      const {
        siteId,
        productId
      } = item;
      return Promise.all([
        this.$approve(itemId),
        this.$firedux.ref('sites').child(siteId).child('products').child(productId).set(null),
        this.$firedux.ref('products').child(productId).child('sites').child(siteId).set(null)
      ]);
    }
  }
  angular
    .module('approval.invalidTier', [])
    .component('approvalInvalidTier', {
      controller: Controller,
      templateUrl: 'root/approval/_components/invalidTier/invalidTier-approval.comp.html',
      bindings: {}
    });
}());
