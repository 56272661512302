(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $count(obj) {
      return this._.size(obj);
    }
    $getFilteredVendors(vendors, teamVendors, isAdmin) {
      return isAdmin ? vendors : this._.filter(vendors, vendor => (teamVendors || {})[vendor.key]);
    }
  }
  angular
    .module('vendor.list', [])
    .component('vendorList', {
      controller: Controller,
      templateUrl: 'root/vendor/list/list-vendor.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('vendor.list', {
          abstract: false,
          url: '/list',
          template: '<vendor-list/>'
        });
    });
}());
