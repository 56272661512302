(function () {
  'use strict';
  function isAddress(address) {
    return angular.isString(address.address1) &&
            (angular.isString(address.address2) || !address.address2) &&
            angular.isString(address.city) &&
            angular.isString(address.state) &&
            angular.isString(address.zip);
  }
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.siteId) &&
      angular.isObject(action.site) &&
      angular.isString(action.site.displayName) &&
      angular.isString(action.site.contactName) &&
      angular.isString(action.site.contactPhone) &&
      isAddress(action.site.address)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    return $firedux
      .ref('sites')
      .child(action.siteId)
      .child('metadata')
      .transaction((site) => {
        site = site || {};
        return {
          isApproved: true,
          teamId: site.teamId || null,
          displayName: action.site.displayName,
          contactName: action.site.contactName,
          contactPhone: action.site.contactPhone,
          email: action.site.email || null,
          address: action.site.address,
          tier: action.site.tier,
          tierSet: true,
          userId: site.userId || $firedux.auth.uid,
          notes: angular.isString(action.site.notes) ? action.site.notes : null
        };
      });
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'SITE.UPDATE',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
