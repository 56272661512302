(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.userId)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    return Promise.all([
      action.teamId ? $firedux.ref('teams').child(action.teamId).child('users').child(action.userId).remove() : Promise.resolve(),
      $firedux
        .ref('users')
        .child(action.userId)
        .child('REMOVE')
        .set(true)
    ]);
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'USER.REMOVE',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
