(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this._ = $window._;
    }
    $getUsers(user) {
      const {_, $firedux} = this;
      if (user.isAdmin) {
        $firedux.ref('users').once('value')
          .then(s => s.val())
          .then(users => _(users).chain().map((u, userId) => Object.assign(u, {key: userId})).sortBy('timestamp').value().reverse())
          .then(data => {
            this.$users = data;
            $firedux.$apply();
          });
      } else if (user.isSubadmin && user.teamId) {
        $firedux.ref('teams').child(user.teamId).child('users').once('value')
          .then(s => s.val())
          .then(teamUsers => Promise.all(_.map(teamUsers, (u, userId) => $firedux.ref('users').child(userId).once('value').then(s => Object.assign({key: userId}, s.val())))))
          .then(data => {
            this.$users = data;
            $firedux.$apply();
          });
      }
    }
  }
  angular
    .module('user.list', [])
    .component('userList', {
      controller: Controller,
      templateUrl: 'root/user/list/list-user.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('user.list', {
          abstract: false,
          url: '/list?embedded',
          template: '<user-list/>'
        });
    });
}());
