(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('supplier.add', [])
    .component('supplierAdd', {
      controller: Controller,
      templateUrl: 'root/supplier/add/add-supplier.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('supplier.add', {
          abstract: false,
          url: '/add?redirectBack',
          template: '<supplier-add/>'
        });
    });
}());
