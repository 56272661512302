(function () {
  'use strict';
  class Controller {
  }
  angular
    .module('product.reviews', [])
    .component('productReviews', {
      controller: Controller,
      templateUrl: 'root/product/_components/reviews/reviews-product.comp.html',
      bindings: {
        form: '&',
        productId: '<'
      }
    });
}());
