(function () {
  'use strict';
  angular
    .module('supplier', [
      // Components
      'supplier.detail',
      'supplier.metadata',
      'supplier.products',
      // Routes
      'supplier.list',
      'supplier.add',
      'supplier.update'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/supplier', '/supplier/list');
      $stateProvider
        .state('supplier', {
          abstract: false,
          url: '/supplier?supplierId',
          template: '<ui-view/>'
        });
    });
}());
