(function () {
  'use strict';
  class Controller {
    constructor($window, $http) {
      this.$window = $window;
      this.$http = $http;
    }
    $impersonate(uid) {
      this.$window.firebase.auth().currentUser.getToken().then(token =>
        this.$http.get(`https://us-central1-drip-1e853.cloudfunctions.net/godLogin?uid=${uid}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      ).then(({data}) =>
        this.$window.firebase.auth().signInWithCustomToken(data).then(() =>
          this.$window.location.replace('/')
        )
      );
    }
  }
  angular
    .module('user.update', [])
    .component('userUpdate', {
      controller: Controller,
      templateUrl: 'root/user/update/update-user.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('user.update', {
          abstract: false,
          url: '/update/:userId?embedded',
          template: '<user-update/>'
        });
    });
}());
