(function () {
  'use strict';
  angular
    .module('product', [
      // Components
      'product.detail',
      'product.metadata',
      'product.reviews',
      'product.sites',
      'product.reports',
      // Routes
      'product.add',
      'product.update',
      'product.list'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/product', '/product/list');
      $stateProvider
        .state('product', {
          abstract: false,
          url: '/product',
          template: '<ui-view/>'
        });
    });
}());
