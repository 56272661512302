(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
      this.moment = $window.moment;
      this.reviews = {};
    }
    $addReview(review, key, productPrice) {
      this.reviews[key] = review;
      this.chart = this.$getChartData(this.reviews, productPrice);
    }
    $getChartData(reviews) {
      const {_, moment} = this;
      let reviewArray = _(reviews)
          .chain()
          .toArray()
          .sortBy(({review: {date}}) => date)
          .value(),
          cumulative = 0,
          amountArray = _(reviewArray)
          .chain()
          .pluck('review')
          .map((review) => {
            let amount = review.amount;
            cumulative += amount;
            return cumulative;
          })
          .value(),
          labelArray = _(reviewArray)
          .chain()
          .pluck('review')
          .pluck('date')
          .value();
      return {
        data: _(amountArray).map((item, index) => ({
          y: item,
          x: index
        })),
        options: {
          tooltips: {
            enabled: false
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Units'
                },
                ticks: {
                  beginAtZero: true,
                  callback(value) {
                    return value;
                  }
                }
              }
            ],
            xAxes: [
              {
                type: 'linear',
                scaleLabel: {
                  display: true,
                  labelString: 'Date'
                },
                ticks: {
                  callback(value) {
                    return moment(labelArray[value]).format('MMM DD, YYYY');
                  }
                }
              }
            ]
          }
        }
      };
    }
    getDateString(date) {
      date = new Date(date);
      return date.toLocaleDateString('en-US', {
        hour12: true,
        month: 'short',
        day: '2-digit'
      });
    }
  }
  angular.module('product.reports', []).component('productReports', {
    controller: Controller,
    templateUrl: 'root/product/_components/reports/reports-product.comp.html',
    bindings: {
      form: '&',
      productId: '<'
    }
  });
}());
