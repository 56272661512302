(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isObject(action.vendor) &&
      angular.isString(action.vendor.displayName) &&
      angular.isString(action.vendor.contactName) &&
      angular.isString(action.vendor.contactPhone) &&
      angular.isString(action.vendor.contactEmail)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    let uid = $firedux.var('UID');
    return Promise
      .all([
        $firedux
          .ref('vendors')
          .child(uid)
          .child('metadata')
          .set({
            displayName: action.vendor.displayName,
            contactName: action.vendor.contactName,
            contactPhone: action.vendor.contactPhone,
            contactEmail: action.vendor.contactEmail,
            notes: action.vendor.notes || null,
            logoUrl: action.vendor.logoUrl || null
          }),
        $firedux.ref('teams').child(action.teamId).child('vendors').child(uid).set(true)
      ])
      .then(() => {
        return uid;
      });
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'VENDOR.ADD',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
