(function () {
  'use strict';
  angular
    .module('review', [
      // Components
      'review.detail',
      // 'review.metadata',
      // Routes
      'review.add',
      'review.update'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/review', '/review/list');
      $stateProvider
        .state('review', {
          abstract: false,
          url: '/review',
          template: '<ui-view/>'
        });
    });
}());
