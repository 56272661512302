(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('productUpdateAction', {
      controller: Controller,
      templateUrl: 'actions/product/update/update-product.action.html',
      bindings: {
        productId: '<',
        product: '<',
        then: '&',
        catch: '&'
      }
    });
}());
