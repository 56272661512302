(function () {
  'use strict';
  angular
    .module('team', [
      // Components
      // 'team.reviews',
      // Routes
      'team.list',
      'team.update'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/team', '/team/list');
      $stateProvider
        .state('team', {
          abstract: false,
          url: '/team',
          template: '<ui-view/>'
        });
    });
}());
