(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $count(obj) {
      const {_} = this;
      return _.size(obj);
    }
    $filterProducts(products, teamId, userProducts = {}, userVendorId = '', userVendors = {}, isAdmin = false, isSubadmin = false) {
      const {_} = this;
      return isAdmin ? products : _.filter(products, product => product && product.metadata && _.some([
        admin(),
        subadmin(product),
        rep(product),
        vendor(product)
      ]));
      function admin() {
        return isAdmin;
      }
      function subadmin(product) {
        return isSubadmin && teamId === product.metadata.teamId;
      }
      function rep(product) {
        return userProducts[product.key];
      }
      function vendor(product) {
        return userVendorId === product.metadata.vendorId || userVendors[product.metadata.vendorId];
      }
    }
  }
  angular
    .module('product.list', [])
    .component('productList', {
      controller: Controller,
      templateUrl: 'root/product/list/list-product.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('product.list', {
          abstract: false,
          url: '/list',
          template: '<product-list/>'
        });
    });
}());
