(function () {
  'use strict';
  class Controller {
    constructor($sce, $window, $http, $firedux) {
      this.$sce = $sce;
      this.$http = $http;
      this.$window = $window;
      this.$firedux = $firedux;
    }
    $buildUrl(url) {
      if (this.$window.firebase.auth().currentUser) {
        this.buildUrl(this.$window.firebase.auth().currentUser, url);
      } else {
        let unsubscribe = this.$window.firebase.auth().onAuthStateChanged(currentUser => {
          if (currentUser) {
            unsubscribe();
            this.buildUrl(currentUser, url);
          }
        });
      }
    }
    buildUrl(currentUser, url) {
      const uid = currentUser.uid;
      currentUser.getToken()
        .then(t =>
          this.$http.get(`https://us-central1-drip-1e853.cloudfunctions.net/godLogin?uid=${uid}`, {
            headers: {
              Authorization: `Bearer ${t}`
            }
          })
        )
        .then(({data: token}) => {
          this.$url = this.$sce.trustAsResourceUrl(`${url}?token=${token}`);
          this.$firedux.$apply();
        });
    }
    $trust(url) {
      return this.$sce.trustAsResourceUrl(url);
    }
    $back() {
      this.$window.history.back();
    }
  }
  angular
    .module('site.addProducts', [])
    .component('siteAddProducts', {
      controller: Controller,
      templateUrl: 'root/site/addProducts/addProducts-site.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('site.addProducts', {
          abstract: false,
          url: '/addProducts/:siteId',
          template: '<site-add-products/>'
        });
    });
}());
