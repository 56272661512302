(function () {
  'use strict';
  class Controller {}
  angular
    .module('actions')
    .component('siteUpdateSuppliersAction', {
      controller: Controller,
      templateUrl: 'actions/site/updateSuppliers/updateSuppliers-site.action.html',
      bindings: {
        siteId: '<',
        suppliers: '<',
        then: '&',
        catch: '&'
      }
    });
}());
