(function () {
  'use strict';
  class Controller {
    constructor() {
      this.creationDate = new Date();
      this.$timestamp = Date.now();
    }
  }
  angular
    .module('actions')
    .component('categoryRemoveReviewAction', {
      controller: Controller,
      templateUrl: 'actions/category/removeReview/removeReview-category.action.html',
      bindings: {
        categoryId: '<',
        reviewId: '<',
        then: '&',
        catch: '&'
      }
    });
}());
